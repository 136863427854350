/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmployeeOverviewDetailsDtoResult } from '../models/EmployeeOverviewDetailsDtoResult';
import type { EmployeeOverviewDtoPaginatedListDtoResult } from '../models/EmployeeOverviewDtoPaginatedListDtoResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { EmployeeDtoResult } from '../models/EmployeeDtoResult';
import { EmployeeProjectGroupsDtoResult } from '../models/EmployeeProjectGroupsDtoResult';
import { DepartmentsResult } from '../models/DepartmentsResult';
import { UInt64NullableResult } from '../core/UInt64NullableResult';
import { EmployeeResourceDetailsDto } from '../models/EmployeeResourceDetailsDto';
import type { SkillFilterDto } from '../models/SkillFilterDto';
import { UriResult } from '../models/UriResult';
import { EmployeeOriginsDtoResult } from '../models/EmployeeOriginsDtoResult';
import { PersonalSecurityCheckLevelDto } from '../models';

export class EmployeeService {
  /**
   * @returns EmployeeOverviewDtoPaginatedListDtoResult Success
   * @throws ApiError
   */
  public static employees({
    version,
    searchString,
    qualificationString,
    roles,
    certificates,
    skills,
    focusTopics,
    subjectMatterExpertTopics,
    interestForNationalSecurityProjects,
    personalSecurityCheckLevel,
    skip,
    take,
  }: {
    version: string;
    searchString?: string;
    qualificationString?: string;
    roles?: Array<string>;
    certificates?: Array<string>;
    skills?: Array<Omit<SkillFilterDto, 'name'>>;
    focusTopics?: Array<string>;
    subjectMatterExpertTopics?: Array<string>;
    interestForNationalSecurityProjects?: boolean;
    personalSecurityCheckLevel?: PersonalSecurityCheckLevelDto;
    skip?: number;
    take?: number;
  }): CancelablePromise<EmployeeOverviewDtoPaginatedListDtoResult> {
    return __request(
      OpenAPI,
      {
        method: 'GET',
        url: '/q/api/v{version}/employees',
        path: {
          version: version,
        },
        query: {
          SearchString: searchString,
          QualificationString: qualificationString,
          Roles: roles,
          Certificates: certificates,
          Skills: skills,
          focusTopics: focusTopics,
          subjectMatterExpertTopics: subjectMatterExpertTopics,
          InterestForNationalSecurityProjects: interestForNationalSecurityProjects,
          PersonalSecurityCheckLevel: personalSecurityCheckLevel,
          Skip: skip,
          Take: take,
        },
      },
      // This deviation from the generated code allows to have more control over
      // how the query string is built. This is needed here because the backend expects
      // query params to have the indices array format.
      { arrayFormat: 'indices', allowDots: true }
    );
  }

  /**
   * @returns EmployeeOverviewDetailsDtoResult Success
   * @throws ApiError
   */
  public static employeeOverviewById({
    id,
    version,
  }: {
    id: string;
    version: string;
  }): CancelablePromise<EmployeeOverviewDetailsDtoResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/employees/{id}/overview',
      path: {
        id: id,
        version: version,
      },
    });
  }

  /**
   * @returns EmployeeDtoResult Success
   * @throws ApiError
   */
  public static employeeById({
    id,
    version,
  }: {
    id?: string | null;
    version: string;
  }): CancelablePromise<EmployeeDtoResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/employees/{id}',
      path: {
        id: id,
        version: version,
      },
    });
  }

  /**
   * @returns EmployeeOverviewDto Success
   * @throws ApiError
   */
  public static employeeByName({
    name,
    version,
  }: {
    name?: string | null;
    version: string;
  }): CancelablePromise<EmployeeResourceDetailsDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/employees',
      path: {
        version: version,
      },
      query: {
        Name: name,
      },
    });
  }

  /**
   * @returns EmployeeDtoResult Success
   * @throws ApiError
   */
  public static employeeProjects({
    id,
    version,
    startDate,
    endDate,
  }: {
    id: string | undefined | null;
    version: string;
    startDate: Date | undefined;
    endDate: Date | undefined;
  }): CancelablePromise<EmployeeProjectGroupsDtoResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/employees/{id}/projects',
      path: {
        id: id,
        version: version,
      },
      query: {
        StartDate: startDate?.toISOString(),
        EndDate: endDate?.toISOString(),
      },
    });
  }

  /**
   * @returns EmployeeDtoResult Success
   * @throws ApiError
   */
  public static departments({
    version,
  }: {
    version: string;
  }): CancelablePromise<DepartmentsResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/departments',
      path: {
        version: version,
      },
    });
  }

  /**
   * @returns UInt64NullableResult Success
   * @throws ApiError
   */
  public static getEventPosition({
    id,
    version,
  }: {
    id: string;
    version: string;
  }): CancelablePromise<UInt64NullableResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/employees/event-position/{id}',
      path: {
        id: id,
        version: version,
      },
    });
  }

  /**
   * @returns UriResult Success
   * @throws ApiError
   */
  public static downloadEducation({
    id,
    version,
    educationName,
  }: {
    id: string;
    version: string;
    educationName?: string;
  }): CancelablePromise<UriResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/employees/education/loadFileUrl/{id}',
      path: {
        id: id,
        version: version,
      },
      query: {
        educationName: educationName,
      },
    });
  }

  /**
   * @returns UriResult Success
   * @throws ApiError
   */
  public static downloadCertificate({
    id,
    version,
    certificateName,
  }: {
    id: string;
    version: string;
    certificateName?: string;
  }): CancelablePromise<UriResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/employees/certificate/loadFileUrl/{id}',
      path: {
        id: id,
        version: version,
      },
      query: {
        certificateName: certificateName,
      },
    });
  }

  /**
   * @returns UriResult Success
   * @throws ApiError
   */
  public static downloadNationalSecurityInfoCertificate({
    id,
    version,
  }: {
    id: string;
    version: string;
  }): CancelablePromise<UriResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/employees/national-security-info/loadFileUrl/{id}',
      path: {
        id: id,
        version: version,
      },
    });
  }

  public static getOrigins({
    version,
  }: {
    version: string;
  }): CancelablePromise<EmployeeOriginsDtoResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/employees/origin',
      path: {
        version: version,
      },
    });
  }
}
