import { useCallback, useState } from 'react';
import { useRecoilState } from 'recoil';
import { employeeFilterAtom, EmployeeFilterAtomState } from '../../../state/atoms';

const SEARCH_DELAY = 300;

export const useEmployeeFilter = () => {
  const [filter, setFilter] = useRecoilState<EmployeeFilterAtomState>(employeeFilterAtom);
  const [searchDebounce, setSearchDebounce] = useState<NodeJS.Timeout | null>(null);

  type FilterKey = keyof EmployeeFilterAtomState;
  type FilterValue<K extends FilterKey> = EmployeeFilterAtomState[K];

  const updateFilterValue = useCallback(
    <K extends FilterKey>(key: K, value: FilterValue<K>) => {
      if (key === 'searchString' || key === 'qualificationString') {
        if (searchDebounce) {
          clearTimeout(searchDebounce);
        }
        setSearchDebounce(
          setTimeout(() => {
            setFilter((prev: EmployeeFilterAtomState) => ({
              ...prev,
              [key]: value,
            }));
          }, SEARCH_DELAY)
        );
        return;
      }

      setFilter((prev: EmployeeFilterAtomState) => ({
        ...prev,
        [key]: value,
      }));
    },
    [searchDebounce, setFilter]
  );

  return {
    filter,
    updateFilterValue,
  };
};
